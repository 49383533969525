// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-md": () => import("./../../../src/pages/about.md" /* webpackChunkName: "component---src-pages-about-md" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cullai-js": () => import("./../../../src/pages/cullai.js" /* webpackChunkName: "component---src-pages-cullai-js" */),
  "component---src-pages-help-cullai-js": () => import("./../../../src/pages/help/cullai.js" /* webpackChunkName: "component---src-pages-help-cullai-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opticull-js": () => import("./../../../src/pages/opticull.js" /* webpackChunkName: "component---src-pages-opticull-js" */),
  "component---src-pages-privacy-md": () => import("./../../../src/pages/privacy.md" /* webpackChunkName: "component---src-pages-privacy-md" */),
  "component---src-pages-privacy-old-06-04-2021-md": () => import("./../../../src/pages/privacy-old-06-04-2021.md" /* webpackChunkName: "component---src-pages-privacy-old-06-04-2021-md" */),
  "component---src-pages-privacy-old-14-01-2023-md": () => import("./../../../src/pages/privacy-old-14-01-2023.md" /* webpackChunkName: "component---src-pages-privacy-old-14-01-2023-md" */),
  "component---src-pages-privacy-old-30-05-2023-md": () => import("./../../../src/pages/privacy-old-30-05-2023.md" /* webpackChunkName: "component---src-pages-privacy-old-30-05-2023-md" */),
  "component---src-pages-terms-md": () => import("./../../../src/pages/terms.md" /* webpackChunkName: "component---src-pages-terms-md" */),
  "component---src-templates-ghost-article-js": () => import("./../../../src/templates/ghost/article.js" /* webpackChunkName: "component---src-templates-ghost-article-js" */),
  "component---src-templates-ghost-category-js": () => import("./../../../src/templates/ghost/category.js" /* webpackChunkName: "component---src-templates-ghost-category-js" */)
}

