module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"65d2aa89fd6eacdfe86d782c7188718a"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/shailesh/Dev/Projects/DopeAi/Repos/dopeai-web/src/templates/ghost/page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":false,"ordered":false,"fromHeading":2,"toHeading":3,"className":"table-of-contents"}},{"resolve":"gatsby-remark-autolink-headers","options":{"className":"ml-2 opacity-0 group-hover:opacity-90 inline-block","isIconAfterHeader":true,"elements":["h2","h3"]}},{"resolve":"gatsby-remark-embed-video","options":{"width":768}},{"resolve":"gatsby-remark-images","options":{"maxWidth":768,"withWebp":true}}],"extensions":[".mdx",".md"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/shailesh/Dev/Projects/DopeAi/Repos/dopeai-web"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBG5V99","includeInDevelopment":false,"routeChangeEventName":"Loaded a Page","defaultDataLayer":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
